const getters = {
  sidebar: state => state.app.sidebar,
  token: state => state.user.token,
  uid: state => state.user.uid,
  pc_user_route: state => state.user.pc_user_route,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  routes: state => state.user.routes,
  department: state => state.user.department,
  role_identifys: state => state.user.role_identifys,
  menus: state => state.user.menus,
  quick_menus: state => state.user.quick_menus,
  search_applications: state => state.user.search_applications,
  first_route_path: state => state.user.first_route_path,
  dynamic_auth: state => state.user.dynamic_auth,
  tdl_view_type: state => state.user.tdl_view_type,
  dist_timestamp: state => state.user.dist_timestamp,
  service_mode: state => state.user.serviceMode,
  version: state => state.user.version,
  show_plan_detail: state => state.plan.show_plan_detail,
  show_plan_form: state => state.plan.show_plan_form,
  show_plan_list: state => state.plan.show_plan_list,
  show_plan_list_wechat: state => state.plan.show_plan_list_wechat,
  show_plan_list_video_wechat: state => state.plan.show_plan_list_video_wechat,
  show_join_plan_form: state => state.plan.show_join_plan_form,
  plan_form_select_disable: state => state.plan.plan_form_select_disable,
  plan_package_name: state => state.plan.plan_package_name,
  plan_packages: state => state.plan.plan_packages,
  show_step: state => state.user.show_step,
  pc_show_step: state => state.user.pc_show_step,
}

export default getters
