import request from "@/utils/request"
import qs from "qs"

export function getPcIndex(params) {
  return request({
    url: "/admin/pc.index/index",
    method: "post",
    params,
  })
}
export function getUserCommonInfo(params) {
  return request({
    url: "admin/member/getUserCommonInfo",
    method: "post",
    params,
  })
}

/**
 * 我的日程
 * @param {*} params
 * @returns
 */
export function agenda(params) {
  return request({
    url: "/admin/pc.index/agenda",
    method: "post",
    params,
  })
}

/**
 * 审批待办
 * @param {*} params
 * @returns
 */
export function getApproveInfo(params) {
  return request({
    url: "/admin/ui2.ph_to_do_list_controller/indexData",
    method: "post",
    params,
  })
}

/**
 * 商务数据
 * @param {*} params
 * @returns
 */
export function getDataStatistics(params) {
  return request({
    url: "/admin/advertise_order/dataStatistics",
    method: "get",
    params,
  })
}

/**
 * 内容营收数据
 * @param {*} params
 * @returns
 */
export function getMonthStatistics(params) {
  return request({
    url: "/admin/advertise_order/monthStatistics",
    method: "get",
    params,
  })
}

/**
 * 剪辑工单占比图表
 * @param {*} params
 * @returns
 */
export function clipOrderAnalysis(params) {
  return request({
    url: "/admin/contentmanage.clip_order/clipOrderAnalysis",
    method: "get",
    params,
  })
}

/**
 * 工单日新增趋势
 * @param {*} params
 * @returns
 */
export function clipOrderNewTrend(params) {
  return request({
    url: "/admin/contentmanage.clip_order/clipOrderNewTrend",
    method: "get",
    params,
  })
}

/**
 * 菜单点击上报
 * @param {*} data
 * @returns
 */
export function addNum(data) {
  return request({
    url: "/admin/ui2.ph_apply_num_controller/addNum",
    method: "post",
    data: qs.stringify(data),
  })
}

/**
 * 最近常用列表
 * @param {*} params
 * @returns
 */
export function recentlyUsed(params) {
  return request({
    url: "/admin/ui2.ph_apply_num_controller/index",
    method: "get",
    params,
  })
}

/**
 * 用户信息
 * @param {*} params
 * @returns
 */
export function userinfo(params) {
  return request({
    url: "/admin/member/userinfo",
    method: "get",
    params,
  })
}

export function editStaffByUid(data) {
  return request({
    url: "/admin/staff/editStaffByUid",
    method: "post",
    data: qs.stringify(data),
  })
}

/**
 * 首页顶部搜索菜单列表
 * @param {*} params
 * @returns
 */
export function getApplications(params) {
  return request({
    url: "/admin/ui2.mobile_menu/applications",
    method: "get",
    params,
  })
}

/**
 * 内容工单所属平台平台
 */
export function getPlatforms() {
  return [
    {
      label: "小红书",
      value: 1,
    },
    {
      label: "抖音",
      value: 2,
    },
    {
      label: "快手",
      value: 3,
    },
  ]
}

/**
 * 图标颜色列表
 * @returns
 */
export function getColorList() {
  return [
    {
      s: "#50CEFB",
      e: "#50CEFB",
    },
    {
      s: "#BACEFD",
      e: "#BACEFD",
    },
    {
      s: "#FFCA4F",
      e: "#FFCA4F",
    },
    {
      s: "#FF8843",
      e: "#FF8843",
    },
    {
      s: "#3370FF",
      e: "#3370FF",
    },
  ]
}

export function readToDo(data) {
  return request({
    url: "/admin/ui2.ph_to_do_list_controller/readToDo",
    method: "post",
    data: qs.stringify(data),
  })
}
